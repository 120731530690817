import { createPageUrl, SECTION_CATEGORY } from '@wix/communities-blog-client-common';
import { getEntityCount, getPaginationPageSize } from '../../selectors/pagination-selectors';
import { getSectionUrl } from '../../store/topology/topology-selectors';
import { formatTitle, getLastPage } from '../pagination';

export const getPaginationUrls = (url, page, lastPage, state) => {
  const urls = {};
  if (page > 1) {
    urls.prevUrl = createPageUrl(page - 1, url || getSectionUrl(state));
  }
  if (page < lastPage) {
    urls.nextUrl = createPageUrl(page + 1, url || getSectionUrl(state));
  }
  return urls;
};

const getPaginationTitle = (title, page, lastPage, t, updateTitle) => {
  if (updateTitle) {
    return formatTitle({ title, page, lastPage, t });
  }
  return title;
};

export const getPaginationItemData = ({ itemData, state, url, page, t, updateTitle }) => {
  const pageSize = getPaginationPageSize(state, SECTION_CATEGORY);
  const entityCount = getEntityCount(state, 'posts');
  const lastPage = getLastPage(entityCount, pageSize);

  const title = getPaginationTitle(itemData.title, page, lastPage, t, updateTitle);
  const urls = getPaginationUrls(url, page, lastPage, state);

  return { ...itemData, ...urls, title };
};
