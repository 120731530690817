import { createAction } from '@wix/communities-blog-client-common';
import { get } from 'lodash';
import { getMultilingualLanguage } from '../../services/get-current-site-language';

export const SET_LOCALE = 'locale/SET';
export const setLocale = createAction(SET_LOCALE);

export const SET_QUERY_LOCALE = 'locale/SET_QUERY';
export const setQueryLocale = createAction(SET_QUERY_LOCALE);

export const setQueryLocaleAction = (wixCodeApi, appParams) => (dispatch) => {
  if (!wixCodeApi || !appParams) {
    return;
  }

  const isMultilingualInstalled = get(wixCodeApi, 'window.multilingual.isEnabled');
  if (!isMultilingualInstalled) {
    return;
  }

  const multilingualLanguage = getMultilingualLanguage(wixCodeApi);
  if (multilingualLanguage) {
    dispatch(setQueryLocale(multilingualLanguage));
  }
};
