import { SET_POST_EDITOR_POST, CLEAN_POST_EDITOR_STATE, UPDATE_POST_EDITOR_POST } from './post-editor-actions';

export const INITIAL_STATE = {};

export default function postEditor(state = INITIAL_STATE, action) {
  switch (action.type) {
    case CLEAN_POST_EDITOR_STATE:
      return {};
    case UPDATE_POST_EDITOR_POST:
      return {
        ...state,
        ...action.payload,
      };
    case SET_POST_EDITOR_POST:
      return action.payload;
    default:
      return state;
  }
}
