import { CreateControllerFn } from '@wix/yoshi-flow-editor';
import { editorAdapter } from '../../external/common/controller/editor-adapter';
import { createPostPageController } from '../../external/post-page/controller';
import { createOldPostPageController } from '../../external/post-page/controller/create-old-post-page-controller';

const createController: CreateControllerFn = async ({ appData, controllerConfig, flowAPI }) => {
  return editorAdapter({
    appData: appData || {},
    controllerConfig,
    flowAPI,
    createNoopController: createOldPostPageController,
    createController: createPostPageController,
  });
};

export default createController;
