import { BLOG_APP_ID } from '@wix/communities-universal/dist/src/constants/appsConfig';

// Single duplexer instance should be reused across app so there would be only one socket open,
// so instance creation function is memoized
let memoizedDuplexerClient;

export async function createDuplexerClient({ getInstance }) {
  if (memoizedDuplexerClient) {
    return memoizedDuplexerClient;
  }
  const { Duplexer } = await import(/* webpackChunkName: "duplexer" */ './duplexer');

  memoizedDuplexerClient = new Duplexer(`duplexer.wix.com`, {
    instanceUpdater: {
      getInstance,
    },
  });
  return memoizedDuplexerClient;
}

export async function createDuplexerConnection({ getInstance }) {
  const client = await createDuplexerClient({ getInstance });
  return client.connect({ appDefId: BLOG_APP_ID });
}

export async function createWixDuplexerConnection({ duplexerUrl, blogApiBaseUrl, instance }) {
  const { Duplexer } = await import(/* webpackChunkName: "wix-duplexer" */ './wix-duplexer');

  const duplexerClient = new Duplexer(duplexerUrl);
  return duplexerClient.connect({
    apiKey: '8dd942aa-6255-4bb0-b004-8bd6cf2f6b35', // HACK
    auth: {
      url: `${blogApiBaseUrl}/_api/duplexer/auth`,
      onBeforeRequest: () => ({
        headers: {
          instance,
        },
      }),
    },
  });
}
