import { get, identity, intersection, map, trim } from 'lodash';

import { removeCorruptedEntities } from '@wix/communities-blog-client-common';
import { getCategoryIds } from '../selectors/categories-selectors';

const normalizeCategoryIds = (post, blogCategoryIds) =>
  post.categories
    ? post.categories.map((category) => category._id)
    : discardDeletedCategoryIds(post.categoryIds, blogCategoryIds);

export const getOwnerTitle = (post) => trim(get(post, 'owner.name'));

export const discardDeletedCategoryIds = (postCategoryIds, blogCategoryIds) =>
  intersection(postCategoryIds || [], blogCategoryIds);

const normalizePostPublications = (post) => {
  const { legacyId, likesCount, viewsCount, commentsCount } = post;
  return { ...post, _id: legacyId, likeCount: likesCount, viewCount: viewsCount, totalComments: commentsCount };
};

const getCustomNormalizer = (origin) => {
  switch (origin) {
    case '/v2/posts/publications':
      return normalizePostPublications;
    default:
      return identity;
  }
};

export const normalizePost = ({ post, blogCategoryIds, origin }) => {
  const normalize = getCustomNormalizer(origin);
  const normalized = { ...normalize(post), categoryIds: normalizeCategoryIds(post, blogCategoryIds) };

  if (normalized.content) {
    normalized.content = removeCorruptedEntities(normalized.content);
  }

  const draft = normalized.draft;
  if (!draft) {
    return normalized;
  }

  draft.categoryIds = normalizeCategoryIds(draft, blogCategoryIds);

  if (draft.content) {
    draft.content = removeCorruptedEntities(draft.content);
  }

  if (post.firstPublishedDate && !draft.firstPublishedDate) {
    draft.firstPublishedDate = post.firstPublishedDate;
  }

  return normalized;
};

export const normalizePosts = ({ posts, blogCategoryIds, discardCategories, origin }) =>
  map(posts, (post) => normalizePost({ post, blogCategoryIds, discardCategories, origin }));

export const normalizePostEntities = (entities, state) =>
  map(entities, (entity) =>
    entity.type === 'post' ? normalizePost({ post: entity, blogCategoryIds: getCategoryIds(state) }) : entity,
  );

export const hasUnpublishedChanges = (post) => Boolean(post && post.hasUnpublishedChanges);
