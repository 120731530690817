let localizationApi;

export const getLocalizationApi = async ({ isSSR } = {}) => {
  if (localizationApi || isSSR) {
    return localizationApi;
  }

  localizationApi = (await import(/* webpackChunkName: "localization-api" */ './localization-api-chunk')).default;

  return localizationApi;
};
