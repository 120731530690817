import { getCurrentUserRole, getCurrentUserSiteMemberId } from '@wix/communities-blog-client-common';
import { getInstanceValue } from '../../store/instance-values/instance-values-selectors';
import { SEARCH_SUBMITTED } from '../../actions/search';
import { get } from 'lodash';

const handler = (action, state) => {
  const { post, query } = action.payload;
  return {
    evid: 216,
    post_stable_id: get(post, 'id'),
    post_id: get(post, '_id'),
    search_term: query,
    role: getCurrentUserRole(state),
    site_member_id: getCurrentUserSiteMemberId(state),
    visitor_id: getInstanceValue(state, 'aid') || getInstanceValue(state, 'uid'),
    eventMeta: {
      description: 'search submitted',
    },
  };
};

export const uouSearchSubmittedEvent = { [SEARCH_SUBMITTED]: handler };
