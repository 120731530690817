import { OPEN_POST_PAGE_IN_EDITOR } from '../actions/open-post-page-editor';

const handler = () => ({
  evid: 350,
  eventMeta: {
    description: 'Post page opened in editor',
  },
});

export const usersOpenPostPageEditorEvent = { [OPEN_POST_PAGE_IN_EDITOR]: handler };
