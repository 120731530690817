import { noop } from 'lodash';
import { getLanguage } from '../store/basic-params/basic-params-selectors';
import { createPromisifiedAction } from '../actions-promisifier/create-promisified-action';

export const requestLogin =
  (mode) =>
  (dispatch, getState, { wixCodeApi }) => {
    const lang = getLanguage(getState());
    const options = { lang };
    if (mode !== undefined) {
      options.mode = mode;
    }
    return wixCodeApi.user.promptLogin(options).catch(noop);
  };

export const requestLoginPromisified = createPromisifiedAction(requestLogin, (result = {}) => ({
  id: result.id,
  loggedIn: result.loggedIn,
  role: result.role,
}));
