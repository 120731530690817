import { createAction } from '@wix/communities-blog-client-common';

export const PAGINATION_USE_NEW_REDUCER = 'pagination/USE_NEW_REDUCER';
export const PAGINATION_LOAD_PAGE = 'pagination/PAGINATION_LOAD_PAGE';
export const PAGINATION_INVALIDATE_PAGES = 'pagination/INVALIDATE_PAGES';
export const PAGINATION_SET_ENTITIES = 'pagination/SET_ENTITIES';
export const PAGINATION_SET_ENTITY_IDS = 'pagination/SET_ENTITY_IDS';
export const PAGINATION_CLEAR_COLLECTION = 'pagination/CLEAR_COLLECTION';

export const paginationUseNewReducer = createAction(PAGINATION_USE_NEW_REDUCER);
export const paginationLoadPage = createAction(PAGINATION_LOAD_PAGE);
export const paginationInvalidatePages = createAction(PAGINATION_INVALIDATE_PAGES);
export const paginationSetEntities = createAction(PAGINATION_SET_ENTITIES);
export const paginationSetEntityIds = createAction(PAGINATION_SET_ENTITY_IDS);
export const paginationClearCollection = createAction(PAGINATION_CLEAR_COLLECTION);
