import { ARCHIVE_PARAMS } from '@wix/communities-blog-client-common';

import { getQueryLocale } from '../../common/selectors/locale-selectors';
import getEnvironment from '../../common/services/get-environment';
import { handleExperimentsResponse } from '../../common/store/experiments/experiments-actions';
import { handleArchiveWidgetResponse } from '../aggregated-archive/actions';
import { getMonthsDisplayLimit, getShowNewestPostsFirst } from '../selectors/archive-selectors';

export const fetchArchiveWidgetInitialData =
  () =>
  async (dispatch, getState, { aggregatorRequest, wixCodeApi }) => {
    const state = getState();
    const langQuery = getQueryLocale(state);
    const language = langQuery ? `&language=${langQuery}` : '';
    const showNewestPostsFirst = getShowNewestPostsFirst(state);

    const { isEditor, isPreview } = getEnvironment(wixCodeApi);
    const monthsDisplayLimit =
      isEditor || isPreview ? ARCHIVE_PARAMS.monthsDisplayLimit.maxValue : getMonthsDisplayLimit(state);

    const { experiments, archive } = await aggregatorRequest(
      `/v1/archive-widget/render-model?months=${monthsDisplayLimit}&showNewestPostsFirst=${showNewestPostsFirst}${language}`,
      {
        throwOnInvalidJson: true,
      },
    );

    await Promise.all([
      dispatch(handleExperimentsResponse(experiments)),
      dispatch(handleArchiveWidgetResponse(archive)),
    ]);
  };
