import { PAGE_OPENED } from '../../actions/page-opened';
import {
  FEED_PAGE,
  CATEGORY_PAGE,
  POST_PAGE,
  FEED_PAGE_NOT_FOUND,
  POST_PAGE_NOT_FOUND,
} from '../../services/detect-route';
import { categoryIds, isPublished, paidPlansHasAccess, paidPlanIds } from '../event-helpers';

const handler = (action) => {
  switch (action.payload.page) {
    case FEED_PAGE: {
      return {
        evid: 200,
        eventMeta: {
          isNavigation: true,
          description: 'home page opened',
        },
      };
    }
    case CATEGORY_PAGE:
      return {
        evid: 201,
        category_id: action.payload.category._id,
        eventMeta: {
          isNavigation: true,
          description: 'category page opened',
        },
      };
    case POST_PAGE: {
      const { post, readingSessionId, referrer } = action.payload;

      return {
        evid: 202,
        reading_session_id: readingSessionId,
        referrer,
        post_id: post._id,
        post_stable_id: post.id,
        paid_plan_has_access: paidPlansHasAccess(post),
        paid_plan_id: paidPlanIds(post),
        category_ids: categoryIds(post),
        is_published: isPublished(post),
        eventMeta: {
          isNavigation: true,
          description: 'post page opened',
        },
      };
    }
    case FEED_PAGE_NOT_FOUND:
      return {
        evid: 218,
        page_type: 'feed',
        pathname: action.payload.pathname,
        eventMeta: {
          isNavigation: true,
          description: 'feed not found opened',
        },
      };
    case POST_PAGE_NOT_FOUND:
      return {
        evid: 218,
        page_type: 'post',
        pathname: action.payload.pathname,
        eventMeta: {
          isNavigation: true,
          description: 'post not found opened',
        },
      };
    default:
      return;
  }
};

export const uouPageOpenedEvent = { [PAGE_OPENED]: handler };
