import { identity, omit } from 'lodash';
import { getLogBiToConsole } from '@wix/communities-blog-client-common';

import { getBiEvid } from '../selectors/bi-selectors';

const tryTransformEvent = (transformEvent, log) => (action, state) => {
  try {
    return transformEvent(action, state);
  } catch (e) {
    log('error', 'BI: event transformation failed!', e, action, state);
  }
};

const tryLogEvent = (event, log) => (bi) => {
  const promise = bi.log(omit(event, 'eventMeta'));
  promise.catch((e) => {
    log('error', 'BI: external bi error!', e, event);
  });
  return promise;
};

const createBiMiddleware = ({
  bi,
  eventMap,
  extendEvent = (event) => event,
  shouldInvokeEvent = () => true,
  shouldLogEvent = () => true,
}) => {
  const maybeLog = (action, store) => {
    const transformEvent = eventMap[action.type];

    if (!transformEvent) {
      return identity;
    }

    const state = store.getState();
    const log = getLogBiToConsole(state)
      ? (level, ...message) => {
          // eslint-disable-next-line no-console
          console[level](...message);
        }
      : () => {};
    const biEvent = tryTransformEvent(transformEvent, log)(action, state); // event => bi event

    if (!biEvent || !shouldInvokeEvent(getBiEvid(biEvent), state)) {
      log('info', 'BI: skipping action', action);
      return identity;
    }

    biEvent._ = new Date().getTime();
    // Extend transformEvent with aditional params biToken, visitor_id, member_id
    const extendedEvent = extendEvent(biEvent, state);

    if (!shouldLogEvent(extendedEvent, state)) {
      // not valid because of technical reasons, e.g. missing BI token
      log('info', 'BI: skipping event', extendedEvent);
      return identity;
    }

    // eslint-disable-next-line no-console
    getLogBiToConsole(state) && console.log('BI event', JSON.stringify(extendedEvent), extendedEvent);
    return tryLogEvent(extendedEvent, log);
  };

  return (store) => (next) => (action) => {
    maybeLog(action, store)(bi);

    return next(action);
  };
};

export default createBiMiddleware;
