import { createAction } from '@wix/communities-blog-client-common';

export const INCREMENT_POST_VIEW_COUNT_REQUEST = 'post/INCREMENT_VIEW_COUNT_REQUEST';
export const incrementPostViewCountRequest = createAction(INCREMENT_POST_VIEW_COUNT_REQUEST);

export function incrementPostViewCount(postId) {
  return (dispatch, getState, { request }) => {
    dispatch(incrementPostViewCountRequest({ postId }));
    return request.post(`/_api/posts/${postId}/views`);
  };
}
