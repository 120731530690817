import { createAction } from '@wix/communities-blog-client-common';
import { sanitizeContent } from '@wix/communities-blog-universal/dist/src/utils/sanitize-content';
import { normalizePost } from '../services/post-utils';
import { pickDraftProps } from '@wix/communities-blog-universal/dist/src/post-utils';
import { getCategoryIds } from '../selectors/categories-selectors';

export const UPDATE_POST_REQUEST = 'post/DRAFT_UPDATE_REQUEST';
export const UPDATE_POST_SUCCESS = 'post/DRAFT_UPDATE_SUCCESS';
export const UPDATE_POST_FAILURE = 'post/DRAFT_UPDATE_FAILURE';

export const updatePostRequest = createAction(UPDATE_POST_REQUEST);
export const updatePostSuccess = createAction(UPDATE_POST_SUCCESS);
export const updatePostFailure = createAction(UPDATE_POST_FAILURE);

export function updatePost(post = {}) {
  return (dispatch, getState, { request }) => {
    dispatch(updatePostRequest());

    const promise = request.patch(`/_api/posts/${post._id}`, {
      draft: sanitizeContent(pickDraftProps(post)),
    });

    return promise
      .then((updatedPost) =>
        dispatch(updatePostSuccess(normalizePost({ post: updatedPost, blogCategoryIds: getCategoryIds(getState()) }))),
      )
      .catch(() => dispatch(updatePostFailure()))
      .then(() => promise);
  };
}
