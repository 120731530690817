import { get } from 'lodash';

import { FORM_FACTOR_DESKTOP, FORM_FACTOR_MOBILE } from '../constants/wix-code-api';

export const resolveDeviceType = (wixCodeApi, isCreatedWithResponsiveEditor = false) => {
  const formFactor = get(wixCodeApi, 'window.formFactor', FORM_FACTOR_DESKTOP);
  const isMobileFriendly = get(wixCodeApi, 'window.isMobileFriendly', true);

  if (formFactor !== FORM_FACTOR_MOBILE || (!isCreatedWithResponsiveEditor && !isMobileFriendly)) {
    return FORM_FACTOR_DESKTOP;
  }

  return FORM_FACTOR_MOBILE;
};
