import { get } from 'lodash';
import { urijs } from '@wix/communities-blog-client-common';

export const getFeedSEOTags = (state) => state.feedSEOTags;

export const getFeedSEOTitle = (state) => get(state, 'feedSEOTags.title', '');
export const getFeedSEOLinks = (state) => get(state, 'feedSEOTags.links', []);
export const getFeedSEOMetaTags = (state) => {
  const metaTags = get(state, 'feedSEOTags.metaTags', []);
  const ogUrlMetaTagIndex = metaTags.findIndex((item) => item.property === 'og:url');
  if (ogUrlMetaTagIndex > -1) {
    metaTags[ogUrlMetaTagIndex].content = urijs(metaTags[ogUrlMetaTagIndex].content).readable();
  }
  return metaTags;
};
