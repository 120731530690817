import { POST_PAGE_URLS, WP_BLOGS } from '@wix/communities-universal/dist/src/constants/wix-blogs';
import { BLOG_APP_ID } from '@wix/communities-universal/dist/src/constants/appsConfig';

export const getSectionUrl = async ({ wixCodeApi, instanceId, sectionId }) => {
  if (WP_BLOGS.includes(instanceId)) {
    return POST_PAGE_URLS[instanceId];
  }

  const { url } = await wixCodeApi.site.getSectionUrl({ sectionId, appDefinitionId: BLOG_APP_ID });
  return url.split('?')[0];
};
