import { ADD_TEXT_COLOR_REQUEST, ADD_TEXT_COLOR_SUCCESS, FETCH_RCE_SETTINGS_SUCCESS } from '../actions/rce-settings';

const initialState = { colorSettings: { customColors: [] } };

export default function rceSettingsReducer(state = initialState, action) {
  switch (action.type) {
    case ADD_TEXT_COLOR_REQUEST:
      return {
        ...state,
        colorSettings: {
          customColors: [...state.colorSettings.customColors, action.payload.color],
        },
      };

    case ADD_TEXT_COLOR_SUCCESS:
    case FETCH_RCE_SETTINGS_SUCCESS:
      return action.payload.rceSettings;

    default:
      return state;
  }
}
