import { get } from 'lodash';
import { getOuterUrl, getCustomPostUrl } from '@wix/communities-blog-client-common';
import { getPostPageSectionUrl } from '../store/topology/topology-selectors';

import { SET_POSTS } from '../actions/set-posts';
import { FETCH_POST_SUCCESS } from '../actions/fetch-post';
import { FETCH_CATEGORY_POSTS_SUCCESS } from '../actions/fetch-category-posts';
import { FETCH_TAG_POSTS_SUCCESS } from '../actions/fetch-tag-posts';
import { FETCH_FEED_POSTS_SUCCESS } from '../actions/fetch-feed-posts';
import { FETCH_RECENT_POSTS_SUCCESS } from '../../post-page/actions/fetch-recent-posts';
import { FETCH_RELATED_POSTS_SUCCESS } from '../../post-page/actions/fetch-related-posts';
import { FETCH_SEARCH_SUCCESS } from '../../search/actions/fetch-search-types';
import { PUBLISH_POST_SUCCESS } from '../actions/publish-post-types';
import { POST_PUBLISHED } from '../actions/blog-socket';
import { FETCH_ARCHIVE_SUCCESS } from '../actions/fetch-archive';
import { FETCH_HASHTAG_POSTS_SUCCESS } from '../actions/fetch-hashtag-posts';
import { getLocalizationApi } from '../controller/localization-api';
import {
  INCREMENT_POST_LIKE_COUNT_FAILURE,
  INCREMENT_POST_LIKE_COUNT_REQUEST,
  INCREMENT_POST_LIKE_COUNT_SUCCESS,
} from '../store/post-likes/post-likes-actions-types';

const POST_ACTIONS = [
  SET_POSTS,
  FETCH_POST_SUCCESS,
  FETCH_CATEGORY_POSTS_SUCCESS,
  FETCH_TAG_POSTS_SUCCESS,
  FETCH_FEED_POSTS_SUCCESS,
  FETCH_RECENT_POSTS_SUCCESS,
  FETCH_RELATED_POSTS_SUCCESS,
  FETCH_SEARCH_SUCCESS,
  PUBLISH_POST_SUCCESS,
  POST_PUBLISHED,
  FETCH_ARCHIVE_SUCCESS,
  FETCH_HASHTAG_POSTS_SUCCESS,
];

const POST_LIKE_ACTIONS = [
  INCREMENT_POST_LIKE_COUNT_REQUEST,
  INCREMENT_POST_LIKE_COUNT_SUCCESS,
  INCREMENT_POST_LIKE_COUNT_FAILURE,
];

const POST_FIELDS = [
  'firstPublishedDateFormatted',
  'viewCountFormatted',
  'likeCountFormatted',
  'totalCommentsFormatted',
];

export const postMetadataMiddleware = ({ isSSR, language, isPostListWidget = false }) => {
  let localizationApi;

  return (store) => (next) => async (action) => {
    if (POST_ACTIONS.includes(action.type)) {
      const posts = getPosts(action);
      const postPageSectionUrl = getPostPageSectionUrl(store.getState());

      posts.forEach((post) => {
        post.slug &&
          (post.link =
            getCustomPostUrl(store.getState(), post.slug) || getOuterUrl(`/${post.slug}`, postPageSectionUrl));
      });
    }

    if (isPostListWidget) {
      if (POST_ACTIONS.includes(action.type)) {
        const posts = getPosts(action);

        if (!arePostsLocalized(posts)) {
          localizationApi = await getLocalizationApi({ isSSR });
        }

        posts.forEach((post) => {
          localizationApi && localizationApi.localizePost(post, language);
        });
      } else if (POST_LIKE_ACTIONS.includes(action.type)) {
        localizationApi = await getLocalizationApi({ isSSR });
        localizationApi && localizationApi.localizeLikeCount(action.payload, language);
      }
    }

    return next(action);
  };
};

function getPosts({ type, payload } = {}) {
  switch (type) {
    case FETCH_SEARCH_SUCCESS:
      return get(payload, 'posts', []);
    case FETCH_POST_SUCCESS:
      const post = get(payload, 'post');
      return post ? [post] : [];
    default:
      return Array.isArray(payload) ? payload : [payload];
  }
}

function arePostsLocalized(posts) {
  return posts.every((post) => POST_FIELDS.every((field) => post.hasOwnProperty(field)));
}
