import { createAction } from '@wix/communities-blog-client-common';

export const SET_IS_LOADED = 'isLoaded/SET';

export const setIsLoaded = createAction(SET_IS_LOADED, (entity, id, isLoaded) => ({
  entity,
  id,
  isLoaded,
}));

export const SET_IS_CATEGORY_LOADED = 'category/SET_IS_LOADED';

export const setIsCategoryLoaded = (categoryId, isLoaded) => ({
  type: SET_IS_CATEGORY_LOADED,
  payload: {
    categoryId,
    isLoaded,
  },
});
