import { createAction, ENTITY_TYPE_POSTS, urijs } from '@wix/communities-blog-client-common';
import { buildPaginationRequestParams, getTotalResults } from '../services/pagination';
import { getCategoryIds } from '../selectors/categories-selectors';
import { normalizePosts } from '../services/post-utils';
import { getPageSize } from '../selectors/pagination-selectors';
import { createPromisifiedAction } from '../actions-promisifier/create-promisified-action';
import { getQueryLocale } from '../selectors/locale-selectors';

export const FETCH_FEED_POSTS_REQUEST = 'feedPosts/FETCH_REQUEST';
export const FETCH_FEED_POSTS_SUCCESS = 'feedPosts/FETCH_SUCCESS';
export const FETCH_FEED_POSTS_FAILURE = 'feedPosts/FETCH_FAILURE';

export const fetchFeedPostsRequest = createAction(FETCH_FEED_POSTS_REQUEST);
export const fetchFeedPostsSuccess = createAction(
  FETCH_FEED_POSTS_SUCCESS,
  (payload) => payload,
  (payload, meta) => meta,
);
export const fetchFeedPostsFailure = createAction(FETCH_FEED_POSTS_FAILURE);

const makeRequest = ({ page, pageSize, featuredOnly, excludeContent, fieldsets, request, lang }) => {
  const paginationParams = buildPaginationRequestParams(page, pageSize);
  return request(
    urijs('/_api/posts').query({
      ...paginationParams,
      pinnedFirst: true,
      featuredOnly,
      excludeContent,
      fieldsets,
      ...(lang && { lang }),
    }),
    {
      parseHeaders: true,
    },
  );
};

export default function fetchFeedPosts({
  page = 1,
  pageSize: defaultPageSize,
  entityType = ENTITY_TYPE_POSTS,
  featuredOnly,
  excludeContent,
  section,
  fieldsets,
  preFetch = false,
} = {}) {
  return (dispatch, getState, { request }) => {
    dispatch(fetchFeedPostsRequest({ entityType, page }));
    const pageSize = getPageSize(getState(), { overrideSettingsPageSize: defaultPageSize, section });
    const promise = makeRequest({
      page,
      pageSize,
      featuredOnly,
      excludeContent,
      fieldsets,
      request,
      lang: getQueryLocale(getState()),
    });
    return preFetch ? promise : completeFetchFeedPosts({ entityType, page, pageSize }, promise)(dispatch, getState);
  };
}

export const completeFetchFeedPosts =
  ({ entityType = ENTITY_TYPE_POSTS, page, pageSize }, promise) =>
  async (dispatch, getState) => {
    try {
      const { body = [], headers = {} } = await promise;
      dispatch(
        fetchFeedPostsSuccess(normalizePosts({ posts: body, blogCategoryIds: getCategoryIds(getState()) }), {
          page,
          entityType,
          entityCount: getTotalResults(headers),
          pageSize,
        }),
      );
    } catch (_) {
      dispatch(fetchFeedPostsFailure({ entityType, page }));
    }
    return promise;
  };

export const fetchFeedPostsPromisified = createPromisifiedAction(
  fetchFeedPosts,
  () => null,
  (response) => response.status,
);
