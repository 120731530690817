import {
  POST_LIST_SETTINGS_PARAMS,
  SECTION_POST_LIST,
  getWixDataCategoryId,
  getWixDataTagId,
  urijs,
  handleAggregatorResponseWithHeaders,
} from '@wix/communities-blog-client-common';
import { getAppSettingsNumber, getAppSettingsValue } from '../../common/selectors/app-settings-base-selectors';
import { isExcludePostContentSupported } from '../../common/selectors/layout-selectors';
import { createPromisifiedAction } from '../../common/actions-promisifier/create-promisified-action';
import { buildPaginationRequestParams, getTotalResults } from '../../common/services/pagination';
import setPosts from '../../common/actions/set-posts';
import { setIsLoading } from '../../common/store/is-loading/is-loading-actions';
import { setPostCount } from './set-posts-count';
import { getQueryLocale } from '../../common/selectors/locale-selectors';

const fetchPostListPostsAction =
  (page = 1, _pageSize) =>
  (dispatch, getState, { request }) => {
    const state = getState();
    const pageSize =
      _pageSize ||
      getAppSettingsNumber(
        state,
        POST_LIST_SETTINGS_PARAMS.entityCount.wixParam,
        POST_LIST_SETTINGS_PARAMS.entityCount.defaultValue,
      );
    const featuredOnly = getAppSettingsValue({ state, key: POST_LIST_SETTINGS_PARAMS.isFeatured.appSettingsPath });
    const widgetCategoryId = getWixDataCategoryId(state);
    const widgetTagId = getWixDataTagId(state);
    const params = buildPaginationRequestParams(page, pageSize);
    const lang = getQueryLocale(getState());

    const promise = request(
      urijs('/_api/posts').query({
        ...params,
        pinnedFirst: true,
        featuredOnly,
        excludeContent: isExcludePostContentSupported(state, SECTION_POST_LIST),
        categoryIds: widgetCategoryId ? [widgetCategoryId] : [],
        tagIds: widgetTagId ? [widgetTagId] : [],
        ...(lang && { lang }),
      }),
      { parseHeaders: true },
    );

    return promise;
  };

const createFetchPostListPosts =
  (action) =>
  (...args) =>
  (dispatch) => {
    dispatch(setIsLoading('postListPosts', undefined, true));

    return dispatch(action(...args))
      .then(({ body, headers }) => {
        dispatch(setPosts(body));
        dispatch(setPostCount(getTotalResults(headers)));
      })
      .then(() => dispatch(setIsLoading('postListPosts', undefined, false)))
      .catch(() => dispatch(setIsLoading('postListPosts', undefined, false)));
  };

export const fetchPostListPosts = createFetchPostListPosts(fetchPostListPostsAction);
export const handlePostListPostsResponse = createFetchPostListPosts(handleAggregatorResponseWithHeaders);

export const fetchPostListPostsPromisified = createPromisifiedAction(
  fetchPostListPosts,
  () => null,
  (response) => response.status,
);
