import { createPostPageApi } from './create-post-page-api';
import { get } from 'lodash';

export const createOldPostPageController = ({ appParams, wixCodeApi }) => {
  const isUseNewPlatformApiEnabled = get(appParams, 'baseUrls.useNewPlatformApi') === 'true';

  return Promise.resolve({
    pageReady: () => {},
    exports: () => createPostPageApi(appParams.instance, wixCodeApi, isUseNewPlatformApiEnabled),
  }).catch(console.error);
};
