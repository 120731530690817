import { get } from 'lodash';

const placeholderRegex = new RegExp('{{([^{{]+)}}', 'gm');
let translations;

const decorate = (param, decorator) => {
  switch (decorator) {
    case 'number':
      return Number(param);
    default:
      return param;
  }
};

export const initI18N = (_translations) => {
  translations = _translations;
};

export const translate = (key, params) => {
  if (!translations) {
    return console.error('Translations not initialized.');
  }
  const translation = get(translations, key, key);

  if (!params) {
    return translation;
  }

  const matches = translation.match(placeholderRegex);
  if (!matches) {
    return translation;
  }

  return matches.reduce((acc, match) => {
    const [paramKey, decorator] = match.replace(/[{}\s]/g, '').split(',');
    return acc.replace(match, decorate(params[paramKey], decorator));
  }, translation);
};
