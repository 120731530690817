import { getPost } from '../../selectors/post-selectors';
import { categoryIds, isPublished } from '../event-helpers';
import { DELETE_POST_SUCCESS } from '../../actions/delete-post';

const handler = (action, state) => {
  const post = getPost(state, action.payload);
  return {
    evid: 206,
    category_ids: categoryIds(post),
    is_published: isPublished(post),
    post_id: post._id,
    eventMeta: {
      description: 'post deleted',
    },
  };
};

export const uouDeletePostSuccessEvent = { [DELETE_POST_SUCCESS]: handler };
