import { CLEAR_POSTS } from '../../actions/clear-posts';
import { FETCH_CATEGORY_POSTS_SUCCESS, FETCH_CATEGORY_POSTS_REQUEST } from '../../actions/fetch-category-posts';
import { FETCH_TAG_POSTS_SUCCESS, FETCH_TAG_POSTS_REQUEST } from '../../actions/fetch-tag-posts';
import { FETCH_FEED_POSTS_SUCCESS, FETCH_FEED_POSTS_REQUEST } from '../../actions/fetch-feed-posts';
import { FETCH_ARCHIVE_REQUEST, FETCH_ARCHIVE_SUCCESS } from '../../actions/fetch-archive';
import { FETCH_HASHTAG_POSTS_REQUEST, FETCH_HASHTAG_POSTS_SUCCESS } from '../../actions/fetch-hashtag-posts';
import { DELETE_POST_SUCCESS } from '../../actions/delete-post';
import {
  POST_ENTITY_TYPES,
  ENTITY_TYPE_POSTS,
  ENTITY_TYPE_MANAGE_POSTS_PUBLISHED,
  ENTITY_TYPE_MANAGE_POSTS_DRAFTS,
} from '@wix/communities-blog-client-common';
import { PUBLISH_POST_SUCCESS } from '../../actions/publish-post-types';
import { CREATE_POST_DRAFT_SUCCESS } from '../../actions/create-post-draft-types';
import { PAGINATION_LOAD_PAGE } from './pagination-actions';
import {
  INITIAL_STATE,
  withEntities,
  withCurrentPage,
  withInitialState,
  withEntityInHead,
  withoutEntity,
} from './pagination-old-utils';

const paginationReducerOld = (state = INITIAL_STATE, { type, payload, meta = {} } = {}) => {
  switch (type) {
    // Pagination middleware
    case PAGINATION_LOAD_PAGE:
      return {
        ...state,
        ...withCurrentPage({ state, entityType: payload.entityType, currentPage: payload.page }),
      };
    // POSTS
    case CLEAR_POSTS:
      return {
        ...state,
        ...withInitialState({ state, entityTypes: POST_ENTITY_TYPES }),
      };
    case FETCH_FEED_POSTS_REQUEST:
    case FETCH_CATEGORY_POSTS_REQUEST:
    case FETCH_TAG_POSTS_REQUEST:
    case FETCH_ARCHIVE_REQUEST:
    case FETCH_HASHTAG_POSTS_REQUEST:
      return {
        ...state,
        ...withCurrentPage({ state, entityType: payload.entityType || ENTITY_TYPE_POSTS, currentPage: payload.page }),
      };
    case FETCH_FEED_POSTS_SUCCESS:
    case FETCH_CATEGORY_POSTS_SUCCESS:
    case FETCH_TAG_POSTS_SUCCESS:
    case FETCH_ARCHIVE_SUCCESS:
    case FETCH_HASHTAG_POSTS_SUCCESS:
      return {
        ...state,
        ...withEntities({ state, entities: payload, ...meta, entityType: meta.entityType || ENTITY_TYPE_POSTS }),
      };
    case CREATE_POST_DRAFT_SUCCESS:
      return {
        ...state,
        ...withEntityInHead({ state, entityType: ENTITY_TYPE_MANAGE_POSTS_DRAFTS, entityId: payload._id }),
      };
    case PUBLISH_POST_SUCCESS:
      return {
        ...state,
        ...withoutEntity({ state, entityTypes: [ENTITY_TYPE_MANAGE_POSTS_DRAFTS], entityId: payload._id }),
        ...withEntityInHead({ state, entityType: ENTITY_TYPE_MANAGE_POSTS_PUBLISHED, entityId: payload._id }),
      };
    case DELETE_POST_SUCCESS:
      return {
        ...state,
        ...withoutEntity({ state, entityTypes: POST_ENTITY_TYPES, entityId: payload }),
      };

    default:
      return state;
  }
};

export default paginationReducerOld;
