import { FETCH_PUBLICATIONS_COUNT_SUCCESS } from './blog-stats-actions';

export default function blogStats(state = {}, { type, payload }) {
  switch (type) {
    case FETCH_PUBLICATIONS_COUNT_SUCCESS:
      return { ...state, publicationsCount: payload };
    default:
      return state;
  }
}
