import { getPost } from '../../selectors/post-selectors';
import { isPublished } from '../event-helpers';
import { INCREMENT_POST_LIKE_COUNT_SUCCESS } from '../../store/post-likes/post-likes-actions-types';

const handler = (action, state) => {
  const { _id, isLiked } = action.payload;
  const post = getPost(state, _id);

  return {
    evid: 209,
    flag: Number(isLiked),
    is_published: isPublished(post),
    post_id: _id,
    post_stable_id: post.id,
    eventMeta: {
      description: 'like click',
    },
  };
};

export const uouIncrementPostLikeCountEvent = { [INCREMENT_POST_LIKE_COUNT_SUCCESS]: handler };
