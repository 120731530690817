import { PLATFORMISED_API_URL, PLATFORM_API_URL } from '../constants/api';

const getAPIUrl = (wixCodeApi, isUseNewPlatformApiEnabled = false) => {
  const platformApiUrl = isUseNewPlatformApiEnabled ? PLATFORMISED_API_URL : PLATFORM_API_URL;
  if (wixCodeApi.window.rendering.env !== 'backend') {
    return platformApiUrl;
  }

  const baseUrl = wixCodeApi.location.baseUrl;
  if (baseUrl.includes('wixsite.com')) {
    return `${baseUrl.substring(0, baseUrl.lastIndexOf('/'))}${platformApiUrl}`;
  }

  return `${baseUrl.replace(/(https?:\/\/[^/]+)\/.+/, '$1')}${platformApiUrl}`;
};

export default {
  getAPIUrl,
};
