import { getQueryLocale } from '../../common/selectors/locale-selectors';
import { encodeURISlug } from '../../common/services/slug';

export const FETCH_CATEGORY_REQUEST = 'category/FETCH_REQUEST';
export const FETCH_CATEGORY_SUCCESS = 'category/FETCH_SUCCESS';
export const FETCH_CATEGORY_FAILURE = 'category/FETCH_FAILURE';

export const fetchCategoryRequest = () => ({ type: FETCH_CATEGORY_REQUEST });
export const fetchCategorySuccess = (payload) => ({ type: FETCH_CATEGORY_SUCCESS, payload });
export const fetchCategoryFailure = () => ({ type: FETCH_CATEGORY_FAILURE });

export const fetchCategory =
  (categorySlug) =>
  async (dispatch, getState, { request }) => {
    dispatch(fetchCategoryRequest());

    try {
      const category = await request(`/_api/categories/${encodeURISlug(categorySlug)}`);

      const lang = getQueryLocale(getState());
      if (lang && category.language !== lang) {
        const error = new Error('Category in different language');
        error.status = 404;
        throw error;
      }

      dispatch(fetchCategorySuccess(category));
      return category;
    } catch (error) {
      dispatch(fetchCategoryFailure());
      throw error;
    }
  };
