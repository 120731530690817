import { createAction } from '@wix/communities-blog-client-common';

export const SHOW_MESSAGE = 'message/SHOW';
export const showMessage = createAction(
  SHOW_MESSAGE,
  (content, type = 'info', messageKey = null, linkMessageKey = null, postSlug = null, variables = {}) => ({
    content,
    type,
    messageKey,
    linkMessageKey,
    postSlug,
    variables,
  }),
);
