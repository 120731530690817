import { createStore, combineReducers, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import createRequests from '../../common/services/create-requests';
import commonReducers from '../../common/reducers';
import postStatsByDate from '../../common/store/post-stats-by-date/post-stats-by-date-reducer';
import archiveWidgetReducer from '../aggregated-archive/reducer';

export function createReduxStore({ appParams, wixCodeApi, compId, fedopsLogger, bundleName }) {
  const p = {};

  const { request, platformizedRequest, aggregatorRequest } = createRequests({
    appParams,
    wixCodeApi,
    bundleName,
    getStore: () => p.store,
  });

  const middleware = [
    thunk.withExtraArgument({
      request,
      platformizedRequest,
      wixCodeApi,
      compId,
      appParams,
      fedopsLogger,
      aggregatorRequest,
    }),
  ];

  const reducers = combineReducers({ ...commonReducers, postStatsByDate, archiveWidget: archiveWidgetReducer });

  p.store = createStore(reducers, undefined, applyMiddleware(...middleware));

  return p.store;
}
