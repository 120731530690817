import { FETCH_POST_STATS_BY_DATE_SUCCESS } from './post-stats-by-date-actions';

export default function postStatsByDate(state = [], { type, payload }) {
  switch (type) {
    case FETCH_POST_STATS_BY_DATE_SUCCESS:
      return payload;
    default:
      return state;
  }
}
