import { SET_USER } from './set-user';
import { FETCH_CURRENT_USER_SUCCESS } from './fetch-current-user';
import { FETCH_USER_SUCCESS } from './fetch-user';
import { USER_JOIN_COMMUNITY_SUCCESS } from '../../actions/join-community';
import { USER_PRIVACY_STATUS_PUBLIC } from '@wix/communities-blog-client-common';

export default (state = null, { type, payload = {} } = {}) => {
  switch (type) {
    case SET_USER:
    case FETCH_USER_SUCCESS:
    case FETCH_CURRENT_USER_SUCCESS:
      return { ...(state || {}), ...payload };
    case USER_JOIN_COMMUNITY_SUCCESS:
      return { ...payload, privacyStatus: USER_PRIVACY_STATUS_PUBLIC };
    default:
      return state;
  }
};
