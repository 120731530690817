import { createAction, ENTITY_TYPE_POSTS, SECTION_CATEGORY, urijs } from '@wix/communities-blog-client-common';
import { normalizePostEntities } from '../services/post-utils';
import { getTotalResults } from '../services/pagination';
import { createPromisifiedAction } from '../actions-promisifier/create-promisified-action';
import { getCursor, getPageSize } from '../selectors/pagination-selectors';
import { getQueryLocale } from '../selectors/locale-selectors';

export const FETCH_HASHTAG_POSTS_REQUEST = 'hashtag/FETCH_POSTS_REQUEST';
export const FETCH_HASHTAG_POSTS_SUCCESS = 'hashtag/FETCH_POSTS_SUCCESS';
export const FETCH_HASHTAG_POSTS_FAILURE = 'hashtag/FETCH_POSTS_FAILURE';

export const fetchHashtagPostsRequest = createAction(FETCH_HASHTAG_POSTS_REQUEST);
export const fetchHashtagPostsFailure = createAction(FETCH_HASHTAG_POSTS_FAILURE);
export const fetchHashtagPostsSuccess = createAction(
  FETCH_HASHTAG_POSTS_SUCCESS,
  (payload) => payload,
  (payload, meta) => meta,
);

export const fetchHashtagPosts =
  (hashtag, page = 1, defaultPageSize) =>
  async (dispatch, getState, { request }) => {
    dispatch(fetchHashtagPostsRequest({ hashtag, page }));

    const pageSize = getPageSize(getState(), {
      overrideSettingsPageSize: defaultPageSize,
      section: SECTION_CATEGORY,
    });

    const lang = getQueryLocale(getState());
    const params = {
      offset: page - 1,
      size: pageSize,
      cursor: getCursor(getState(), ENTITY_TYPE_POSTS),
      hashtags: [hashtag],
      ...(lang && { lang }),
    };

    try {
      const path = '/_api/posts';
      const response = await request(urijs(path).query({ ...params }), { parseHeaders: true });

      dispatch(
        fetchHashtagPostsSuccess(normalizePostEntities(response.body, getState()), {
          entityCount: getTotalResults(response.headers),
          pageSize,
          page,
        }),
      );
      return response.body;
    } catch (error) {
      dispatch(fetchHashtagPostsFailure());
      return error.response;
    }
  };

export const fetchHashtagPostsPromisified = createPromisifiedAction(
  fetchHashtagPosts,
  () => null,
  (response) => response.status,
);
