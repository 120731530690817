import { createAction, handleAggregatorResponse } from '@wix/communities-blog-client-common';
import { getQueryLocale } from '../../selectors/locale-selectors';

export const FETCH_PUBLICATIONS_COUNT_REQUEST = 'publicationsCount/FETCH_REQUEST';
export const FETCH_PUBLICATIONS_COUNT_SUCCESS = 'publicationsCount/FETCH_SUCCESS';
export const FETCH_PUBLICATIONS_COUNT_FAILURE = 'publicationsCount/FETCH_FAILURE';

export const fetchPublicationsCountRequest = createAction(FETCH_PUBLICATIONS_COUNT_REQUEST);
export const fetchPublicationsCountSuccess = createAction(FETCH_PUBLICATIONS_COUNT_SUCCESS);
export const fetchPublicationsCountFailure = createAction(FETCH_PUBLICATIONS_COUNT_FAILURE);

const fetchPublicationsCountAction =
  () =>
  (_dispatch, getState, { request }) => {
    const lang = getQueryLocale(getState());
    const langQuery = lang ? `?lang=${lang}` : '';
    return request(`/v2/stats/publications/total${langQuery}`);
  };

const createFetchPublicationsCount =
  (action) =>
  (...args) =>
  (dispatch) => {
    dispatch(fetchPublicationsCountRequest());

    return dispatch(action(...args))
      .then((publicationsCount) => dispatch(fetchPublicationsCountSuccess(publicationsCount || {})))
      .catch(() => dispatch(fetchPublicationsCountFailure()));
  };

export const fetchPublicationsCount = createFetchPublicationsCount(fetchPublicationsCountAction);
export const handlePublicationsCountResponse = createFetchPublicationsCount(handleAggregatorResponse);
